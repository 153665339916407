import React from 'react';
import ImageButton from './ImageButton';

const Balaji = ({ paintings }) => {
    const handleAddToCart = (paint) => {
        // Implement your logic to handle adding items to the cart
        console.log(`Adding ${paint.productName} to cart`);
      };
  const filtered = paintings.filter((painting) => painting.type === "balaji");
  const reversed = filtered.reverse();

  return (
    <div>
      <div className="main">
        <div className="wrap container">
          <div className="section group container">
            <div className="cont span_3_of_3" style={{ width: "100%" }}>
              <h2 className="head">Featured Products</h2>
              {reversed.map((paint) => {
                return (
                  <div
                    className="col_1_of_3 span_1_of_3"
                    style={{
                      maxWidth: "230px",
                      minWidth: "200px",
                      maxHeight: "380px",
                      minHeight: "380px",
                    }}
                    key={paint.id} // Assuming 'id' is a unique identifier for each painting
                  >
                    <div className="inner_content clearfix">
                      <div className="product_image">
                        <img
                          src={paint.imageName}
                          style={{ height: "250px", width: "auto" }}
                          alt="Paintings"
                        />
                      </div>
                      <div className="sale-box">
                        <span className="on_sale title_shop">Sale</span>
                      </div>
                      <ImageButton handleAddToCart={handleAddToCart} paint={paint} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="clear" />
      </div>
    </div>
  );
};

export default Balaji;
