import { Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './pages/Footer';
import Header from './pages/Header';
import Home from './pages/Home';
import Ganesh from './pages/Ganesh';
import Balaji from './pages/Balaji';
import Saraswathi from './pages/Saraswathi';
import Mahalakshmi from './pages/Mahalakshmi';
import Murugan from './pages/Murugan';
import Krishnan from './pages/Krishnan';
import Others from './pages/Others';
import Emboss from './pages/Emboss';
import About from './pages/About';
import Contact from './pages/Contact';
import AdminLogin from './pages/Admin';
import Product from './pages/Admin';
import { useEffect, useState } from 'react';
import Registration from './pages/Register';
import Login from './pages/Login';
import Update from './pages/Update';

function App() {
const[paintings,setPaintings]=useState([])
useEffect(()=>{
  const fetchData = async () => {
    try {
      const response = await fetch('https://kimssoft-001-site3.atempurl.com/api/products');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      const paintingsWithPrefix = result.map((painting) => ({
        ...painting,
        imageName: `https://kimssoft-001-site3.atempurl.com/Images/${painting.imageName}`,
      }));

      setPaintings(paintingsWithPrefix);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
},[])
  return (
    <div>
      <Header/>
      <Routes>
        <Route path="/" element={<Home paintings={paintings}/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/ganesh" element={<Ganesh paintings={paintings}/>}/>
        <Route path="/balaji" element={<Balaji paintings={paintings}/>}/>
        <Route path="/saraswathi" element={<Saraswathi paintings={paintings}/>}/>
        <Route path="/lakshmi" element={<Mahalakshmi paintings={paintings}/>}/>
        <Route path="/murugan" element={<Murugan paintings={paintings}/>}/>
        <Route path="/krishnan" element={<Krishnan paintings={paintings}/>}/>
        <Route path="/others" element={<Others paintings={paintings}/>}/>
        <Route path="/emboss" element={<Emboss paintings={paintings}/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/admin" element={<Login/>}/>
        <Route path="/register" element={<Registration/>}/>
        <Route path="/update/:id" element={<Update/>}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
