import React from 'react'

const Achievements = () => {
  return (
    <div>
       <div className='container'>
       <h3 style={{color:"#4cb1ca"}}>Achievements</h3>
        <div className='row'>
        <div className="col-lg-6 col-md-6">
  <h3><a>The Hindu</a></h3>
 
    <div className="col1">
      <img src="assets/images/achievement/hindu1.jpg" alt title name="hindu" onmouseover="hindu.width='800';hindu.height='620';" onmouseout="hindu.width='500';hindu.height='400';" />			<br />
      <br />		</div>
    <p>&nbsp;</p>
    <div className="clear" />
  </div>
  <div className="col-lg-6 col-md-6">
  <h3><a>Daily Thandhi</a></h3>

    <div className="col1">
      <img src="assets/images/achievement/thandhi.jpg" alt title name="thandhi"  onmouseover="thandhi.width='800';thandhi.height='1150';" onmouseout="thandhi.width='500';thandhi.height='800';" />			<br />
      <br />		</div>
    <p>&nbsp;</p>
    <div className="clear" />
  </div>
  <div className="col-lg-6 col-md-6">
  <h3><a>Dhinakaran</a></h3>

    <div className="col1">
      <img src="assets/images/achievement/thinakaran.jpg" alt title name="dinakaran"  onmouseover="dinakaran.width='800';dinakaran.height='800';" onmouseout="dinakaran.width='500';dinakaran.height='700';" />			<br />
      <br />		</div>
    <p>&nbsp;</p>
    <div className="clear" />
  </div>
  <div className="col-lg-6 col-md-6">
  <h3><a>Dhina malar</a></h3>

    <div className="col1">
      <img src="assets/images/achievement/thinamalar.jpg" alt name="dinamalar" onmouseover="dinamalar.width='800';dinamalar.height='630';" onmouseout="dinamalar.width='500';dinamalar.height='400';" />			<br />
      <br />		</div>
    <div className="clear" />
    </div>
  </div>
  <div className="white_box" style={{marginBottom:"30px"}}>
    <h3><a style={{color:"#4cb1ca"}}> World Record Tanjore Painting </a></h3>
    <img src="assets/images/prev_bg_3.jpg" alt="tanjore painting"/>
  </div>
  <div className="clear" />
</div>

    </div>
  )
}

export default Achievements