import React from 'react';

const ImageButton = ({ handleAddToCart, paint }) => {
  return (
    <div className="price">
      <div className="cart-left">
        <p className="title" style={{ fontSize: "0.9em", color: "black" }}>{paint.productName}</p>
        <div className="price1">
          <span className="actual" style={{ color: "red" }}>&#8377;{paint.price}</span>
        </div>
      </div>
      <div className="buy" style={{ display: "flex", alignItems: "center" }}>
        <button onClick={() => handleAddToCart(paint)} style={{ border: "none", background: "none", padding: "0", cursor: "pointer" }}>
          <img src="../assets/images/buy1.png" alt="Add to cart" style={{ height: "30px", width: "30px" }} />
        </button>
      </div>
      <div className="clear" />
    </div>
  );
};

export default ImageButton;