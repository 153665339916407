import React from 'react'
import Achievements from './Achievements'
import Slider from './Slider'

const About = () => {
  return (
    <div>
    <div className='container'>
        	<div>
  <div>
    <h2 style={{color:"#4cb1ca"}}>Tanjore Paintings</h2>
    <p >"The bejeweled paintings known as Thanjavur Painting have been more   popular in Tamil Nadu than their birthplace, the land of Marathas."</p>
  </div>
  <div >
    <div >
      
      <div style={{marginTop:"30px",marginBottom:"30px"}}>
      <h3 style={{color:"#4cb1ca"}}>About us</h3>
        <div >
          <img src="assets/images/name.png" alt title />			<br />	<br />		</div>
        <p className='text-justify'>The bejeweled paintings, known as Thanjavur  Painting, are more popular in Tamil Nadu than their birthplace, the land of  Marathas.&nbsp; These craft oriented paintings  emerged around religion, demand a high degree of precision and generally  portray the Hindu pantheon of gods and goddesses. This style of painting has become  very popular in the recent years nevertheless in most cases they are only  copies of the ancient works. They are also appreciated for the craftsmanship  they display and also for the richness in detail and creativity. </p>
      </div>
     
      <div style={{marginTop:"30px",marginBottom:"30px"}}>
      <h3 style={{color:"#4cb1ca"}}>About Tanjore Paintings</h3>
      <div className="row">
       <div className='col-md-9 col-lg-9 text-justify'>
          Tanjore paintings evoke a sense of class and timelessness  with their alluring illustrations of puranic scenes. This ancient art form  traces its origin to Thanjavur, capital of the erstwhile Chola Empire and is  popular among contemporary Indian for showcasing their artistic inclination and  tastes.
          Tanjore painting’s most popular form involves depicting characters  in pot surrounded by other figures like consorts, relatives etc. such as  Krishna and Yasodha, Krishna with Rukmani, Sivan Parvathy , Ramar Pattapishekam  are placed within a frame work like a pavilion arches, curtains and decorative  borders.
          The paintings are made of gems and heavy gilding. The  outlines of the jewelry and other beautiful ornamentation have been raised by  putty (applying a mix of natural gum and chalk powder). The colors used are  also prepared from naturals sources.
          Tanjore painting is the best form of art when it comes to  illustrating the Vedic meaning behind each divine avatar. Made of 22-carat gold  foil, Tanjore paintings last for hundreds of years.
          The paintings are decorated by gilde and gem-set technique,  a technique where 22 carat gold foils &amp; sparkling stones are used to  highlight certain aspects of the painting like ornaments, dresses etc.
          The paintings are done in wooden panels. In tamil it is  called “Palagai padam” Three are four stages to be filled in the particular  drawing on which the gems and gold had be placed.
          Tanjore painting is done with devotion and dedication. 
          The richly ornamented works become the icons of the worship  and almost replace bronze images. The paintings stress sanctity rather than  artistic achievement and were sacred objects limited to the pooja rooms.
          </div>
          <div className='col-md-3 col-lg-3'>
            <img src="assets/images/tanjore.jpg" className='img-responsive' alt="" />
          </div>
          </div>
      </div>
     
      <div className='row' style={{marginTop:"30px",marginBottom:"30px"}}>
  
        <div className='col-lg-3 col-md-3 order-2' >
          <img src="assets/images/artist.jpg" alt title />				</div>
          <div className='col-lg-9 col-md-9'>
          <h3 style={{color:"#4cb1ca"}}>About our artist</h3>
        <p className='text-justify'>Our Artist has developed her art work in Tanjore paintings and done so many  paintings in past 15 years. With her vast experience&nbsp; &nbsp;She has  recently done a record breaking mega size (26 ft. X 9 ft.) painting of lord  Krishna’s avatar in which She plays along with the girls (Krishna leela). Her  paintings were displayed in leading exhibitions in Chennai. Her paintings  decorate many prayer rooms in homes and offices all over India and the world.  Quite a large number of her clients are from Malaysia, USA, and Australia. She  takes pride in the fact that most of her clients have come through references  and have placed repeat orders with them.</p>
        </div>
        </div></div></div>

        <h3 style={{color:"#4cb1ca"}}>Our Art Works</h3> 
<div className='row'style={{marginTop:"30px",marginBottom:"30px"}} >
{/* <h3 style={{color:"#4cb1ca"}}>Our Art Works</h3>  */}

 
        <div className='col-lg-4 col-md-4' style={{backgroundColor:"rgba(0,0,0,0.2)",maxHeight:"300px",maxWidth:"300px",borderRadius:"5px", display:"flex",justifyContent:"center",alignItems:"center",margin:"20px",padding:"20px"}}>
        <img className="#4cb1ca" src="assets/images/emboss/ganesh_embosed.jpg" alt="tanjore paintings" style={{width:"auto",height:"280px"}} />
        </div>  
        <div className='col-lg-3 col-md-4' style={{backgroundColor:"rgba(0,0,0,0.2)",maxHeight:"300px",maxWidth:"300px",borderRadius:"5px", display:"flex",justifyContent:"center",alignItems:"center",margin:"20px",padding:"20px"}}>
        <img className="#4cb1ca" src="assets/images/genaral/Peacock.jpg" alt="tanjore art"  style={{width:"auto",height:"280px"}} />  
        </div> 
        <div className='col-lg-3 col-md-4' style={{backgroundColor:"rgba(0,0,0,0.2)",maxHeight:"300px",maxWidth:"300px",borderRadius:"5px", display:"flex",justifyContent:"center",alignItems:"center",margin:"20px",padding:"20px"}}>
        <img className="#4cb1ca" src="assets/images/sample.jpg" alt="embosed painting"  />  
        </div>
  </div>
      
      
    <div className="clear" />
  
</div>
<Achievements/>
    </div>
    </div>
  )
}

export default About