import React from 'react'

const Contact = () => {
  return (
    <div>
        <div className="container">
<div className="login">
    <h3 style={{color:"#4cb1ca"}}>Contact Us</h3>
    <div className="">
      <form method="post" >
        <div className="to">
          <input type="text" className="text" placeholder='Name'/>
          <input type="text" className="text" placeholder='Email' style={{marginLeft: 10}} />
        </div>
        <div className="to">
          <input type="text" className="text" placeholder='Phone Number'/>
          <input type="text" className="text" placeholder='Address' style={{marginLeft: 10}} />
        </div>
        <div className="text">
          <textarea placeholder='Type Your Message' />
        </div>
        <div className="submit">
          <input type="submit" defaultValue="Submit" />
        </div>
      </form>
      <div className="map">
        <iframe width="100%" height={175} frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} src="https://maps.google.co.in/maps?f=q&source=s_q&hl=en&geocode=&q=Lighthouse+Point,+FL,+United+States&aq=4&oq=light&sll=26.275636,-80.087265&sspn=0.04941,0.104628&ie=UTF8&hq=&hnear=Lighthouse+Point,+Broward,+Florida,+United+States&t=m&z=14&ll=26.275636,-80.087265&output=embed" /><br /><small><a href="https://maps.google.co.in/maps?f=q&source=embed&hl=en&geocode=&q=Lighthouse+Point,+FL,+United+States&aq=4&oq=light&sll=26.275636,-80.087265&sspn=0.04941,0.104628&ie=UTF8&hq=&hnear=Lighthouse+Point,+Broward,+Florida,+United+States&t=m&z=14&ll=26.275636,-80.087265" style={{color: '#666', textAlign: 'left', fontSize: 12}}>View Larger Map</a></small>
      </div>
    </div>
  </div> 
    </div>
    </div>
  )
}

export default Contact