import React from 'react'
import { Link } from 'react-router-dom'
import './box.css'

const Header = () => {
  return (
    <div>
      <div>
        <div className="header-top w-100">
          <div className="wrap1">
            <div className="cssmenu">
              <ul>
                <li className="active"><a >Cell : <span style={{color:"white"}}>+91 99522 14909</span> </a></li> |
                <li><a>Landline : <span style={{color:"white"}}>+91 4565 234909</span></a></li> |
                <li><a href="mailto:tanjorearts2015@gmail.com" style={{color:"white",textDecoration:"underline"}}>tanjorearts2015@gmail.com</a></li>
              </ul>
            </div>
            <div className="clear" />
          </div>
        </div>
        <div className="header-bottom">
          <div className="wrap">
            <div className="header-bottom-left">
              <div className="logo" style={{marginLeft:"25px"}}>
                <a ><img src="assets/images/logo.png" alt /></a>
              </div>
              <nav class="navbar navbar-expand-lg navbar-light">

                <a class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" style={{ border: "none" }}>
                  <div style={{ width: "100vw", backgroundColor: "rgba(0,0,0,0.1)", marginLeft: "-36px", padding: "10px",marginTop:"-32px"}}><span class="navbar-toggler-icon"></span><b> Menu</b></div>
                </a>
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                  <ul class="navbar-nav">
                    <li class="nav-item active">
                      <Link to="/" className=" nav-link">Home</Link>
                    </li>
                    <li class="nav-item active">
                      <Link to='/about' className="nav-link">About</Link>
                    </li>
                    <li class="nav-item active">
                      <li><Link to='/ganesh' className="nav-link" style={{ color: "black" }}>Ganesh</Link></li>
                    </li>
                    <li class="nav-item active">
                      <li><Link to='/balaji' className="nav-link" style={{ color: "black" }}>Balaji</Link></li>
                    </li>
                    <li class="nav-item active">
                      <Link to='/saraswathi' className="nav-link">Saraswathi</Link>
                    </li>
                    <li class="nav-item active">
                      <Link to='/lakshmi' className="nav-link">MahaLakshmi</Link>
                    </li>
                    <li class="nav-item active">
                      <Link to='/murugan' className="nav-link">Murugan</Link>
                    </li>
                    <li class="nav-item active">
                      <Link to='/krishnan' className="nav-link">Krishnan</Link>
                    </li>
                    <li class="nav-item active">
                      <Link to='/others' className=" nav-link">Others</Link>
                    </li>
                    <li class="nav-item active" style={{ minWidth: "100px" }}>
                      <Link to='/emboss' className="nav-link">3D embos</Link>
                    </li>
                    <li class="nav-item active">
                      <Link to='/contact' className="nav-link">Contact</Link>
                    </li>
                    <li class="nav-item active">
                      <Link to='/admin' className="nav-link">Admin</Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div className="clear" />
          </div>
          <div className="header-bottom-right" style={{marginRight:"50px"}}>
              <div class="tag-list">
                <ul class="icon1 sub-icon1 profile_img">
                  <li><a class="active-icon c1" href="#"> </a>
                    <ul class="sub-icon1 list">
                      <li><h3>sed diam nonummy</h3><a href=""></a></li>
                      <li><p>Lorem ipsum dolor sit amet, consectetuer  <a href="">adipiscing elit, sed diam</a></p></li>
                    </ul>
                  </li>
                </ul>
                <ul class="icon1 sub-icon1 profile_img">
                  <li><a class="active-icon c2" href="#"> </a>
                    <ul class="sub-icon1 list">
                      <li><h3>No Products</h3><a href=""></a></li>
                      <li><p>Lorem ipsum dolor sit amet, consectetuer  <a href="">adipiscing elit, sed diam</a></p></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
        </div>
      </div>

    </div>
  )
}

export default Header
