import React, { useState, useEffect } from "react";
import './card.css'
import { useNavigate } from "react-router-dom";

const defaultImageSrc = "/img/image_placeholder.png";
const initialFieldValues = {
  productID: 0,
  productName: "",
  description: "",
  price:"",
  type:"",
  imageName: "",
  imageSrc: defaultImageSrc,
  imageFile: null,
};

export default function Product(props) {
  const { addOrEdit, recordForEdit } = props;
  const [values, setValues] = useState(initialFieldValues);
  const[selectedfile,setSelectedfile]=useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const[fetchedData,setFetchedData]=useState([])
  const [errors, setErrors] = useState({});
  const navigate=useNavigate();


    useEffect(() => {
        if (recordForEdit != null) {
            setValues(recordForEdit);
            console.log("recordForEdit:", recordForEdit); // Log the entire object
            if (recordForEdit.description) {
                console.log("Description from recordForEdit:", recordForEdit.description);
            }
        }
    }, [recordForEdit]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

/* fetch data*/
useEffect(()=>{
  const fetchData = async () => {
    try {
      const response = await fetch('https://kimssoft-001-site3.atempurl.com/api/products');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      const paintingsWithPrefix = result.map((painting) => ({
        ...painting,
        imageName: `https://kimssoft-001-site3.atempurl.com/Images/${painting.imageName}`,
      }));
      const reversed=paintingsWithPrefix.reverse();
      setFetchedData(reversed);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
},[])

/*fetch data*/
const fetchData = async () => {
  try {
    const response = await fetch('https://kimssoft-001-site3.atempurl.com/api/products');
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const result = await response.json();
    const paintingsWithPrefix = result.map((painting) => ({
      ...painting,
      imageName: `https://kimssoft-001-site3.atempurl.com/Images/${painting.imageName}`,
    }));
    const reversed=paintingsWithPrefix.reverse();
    setFetchedData(reversed);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

  const validate = () => {
    let temp = {};
    temp.productName = values.productName == "" ? false : true;
    temp.imageSrc = values.imageSrc == defaultImageSrc ? false : true;
    setErrors(temp);
    return Object.values(temp).every((x) => x == true);
  };

  const resetForm = () => {
    setValues(initialFieldValues);
    document.getElementById('image-uploader').value = null;
    setErrors({});
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append("productName", values.productName);
    formData.append("description", values.description);
    formData.append("price", values.price);
    formData.append("type", values.type);
    if (selectedfile) {
      formData.append("imageFile", selectedfile, selectedfile.name);
    }
  
    try {
      let apiUrl = 'https://kimssoft-001-site3.atempurl.com/api/products';
      let method = 'POST';
  

      
      const response = await fetch(apiUrl, {
        method: method,
        body: formData,
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Response:', data);
        resetForm();
      } else {
        console.error('Error:', response.statusText);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };
  
  
  const applyErrorClass = (field) =>
    field in errors && errors[field] == false ? " invalid-field" : "";
    const handleChange = (e) => {
   
        const reader = new FileReader();
        reader.onload = (x) => {
          setSelectedImage(x.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
    
    };
    const onDelete = async (e, id) => {
      e.preventDefault();
      const productId = parseInt(id);
    
      if (window.confirm('Are you sure to delete this record?')) {
        // Log the ID for debugging
        console.log("Deleting product with id:", id);
    
        // Check if productId is a valid number
        if (isNaN(productId)) {
          console.error('Invalid productId:', id);
          return;
        }
    
        try {
          const response = await fetch(`https://kimssoft-001-site3.atempurl.com/api/products/delete/${productId}`, {
            method: 'POST', // Using GET method
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              // other headers...
            }
          });
    
          if (response.ok) {
            // Item deleted successfully
            console.log('Item deleted successfully');
            fetchData();
            // You may want to update your state or re-fetch the data
          } else {
            // Error deleting the item
            console.error('Error deleting item:', response.statusText);
          }
        } catch (error) {
          console.error('Fetch error:', error);
        }
      }
    };
  return (
      <>

      <div className="row" >
  <div className="col-lg-3 col-md-4 sticky-column" >
          <b>Upload Product</b>
      <form autoComplete="off" noValidate onSubmit={handleFormSubmit}>
        <div className="card">
          <div className="card-body">
            <div style={{height:"200px",width:"100%",border:"3px solid grey"}}>
            <img src={selectedImage} className="card-img-top" style={{height:"100%",width:"100%"}}/>
            </div>
            <div className="form-group" style={{padding:"10px",margin:"15px"} }>
              <input
                type="file"
                accept="image/*"
                className={"form-control-file" }
                onChange={(e)=>{ setSelectedfile(e.target.files[0]); handleChange(e);}}
                id="image-uploader"
              />
            </div>

            <div className="form-group" style={{ margin: "15px" }} >
              <h6 className="text-start">Title</h6>
              <input
                className={"form-control"}
                placeholder="product Name"
                name="productName"
                value={values.productName}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group" style={{ margin: "15px" }}>
              <h6 className="text-start">Description</h6>
              <input
                className="form-control"
                placeholder="description"
                name="description"
                value={values.description}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group" style={{ margin: "15px" }}>
              <h6 className="text-start">Price</h6>
              <input
                className="form-control"
                placeholder="price"
                name="price"
                value={values.price}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group" style={{ margin: "15px" }}>
              <h6 className="text-start">Type</h6>
              <select
                className="form-control"
                name="type"
                value={values.type}
                onChange={handleInputChange}
              >
                <option value="ganesh">Ganesh</option>
                <option value="balaji">Balaji</option>
                <option value="saraswathi">Saraswathi</option>
                <option value="lakshmi">Lakshmi</option>
                <option value="murugan">Murugan</option>
                <option value="krishnan">Krishnan</option>
                <option value="others">Others</option>
                <option value="emboss">Emboss</option>
               
              </select>
            </div>

            <div className="form-group text-center">
              <button type="submit" className="btn btn-info">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
      </div>
      {/*___________________*/}
      <div className="col-lg-9 col-md-8" >
        <div className="row">
     
{Array.isArray(fetchedData) &&
  fetchedData.map((data) => {
    return (
      <div
        className="col-lg-3 col-md-3 col-sm-4 col-xs-6"
        key={data.productId}
        onClick={() => navigate(`/update/${data.productId}`)}
      >
        <div className="card">
          <div className="image-container">
            <img src={data.imageName} alt={data.productName} />
          </div>
          <div className="card-body">
            <h5>{data.productName}</h5>
            <p>{data.productId}</p>
            <span>{data.price}</span> <br />
            <button
              className="btn btn-danger delete-button"
              onClick={(e) => onDelete(e, data.productId)}
              style={{ width: "50px" }}
            >
              <i className="fa-solid fa-trash" style={{ height: "100%" }}></i>
            </button>
          </div>
        </div>
      </div>
    );
  })}

</div>
      </div>
      </div>

    </>
  );
}
