import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// ... (other imports and code)

const Update = () => {
    const [data, setFetchedData] = useState([]);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [type, setType] = useState("");
    const [file, setFile] = useState(null);
    const [item, setItem] = useState({});
    const { id } = useParams();
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch('http://re.srinivasalandpromoters.com/api/products');
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const result = await response.json();
          setFetchedData(result);
          const foundItem = result.find((item) => item.productId == id);
          setItem(foundItem || {});
          console.log(foundItem);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, [id]);
  
    const UpdateForm = async (e) => {
        e.preventDefault();
    
        try {
          // Create a FormData object to send the updated data
          const formData = new FormData();
          formData.append("productName", name);
          formData.append("description", description);
          formData.append("price", price);
          formData.append("type", type);
          if (file) {
            formData.append("imageFile", file, file.name);
          }
    
          
          const response = await fetch(`http://re.srinivasalandpromoters.com/api/products/update/${item.productId}`, {
            method: 'POST', // or 'PUT' or 'PATCH' depending on your API
            body: formData,
            headers: {
              'Content-Type': 'application/json', // Adjust the content type based on your API requirements
              // Add other headers as needed
            },
          });
    
          if (response.ok) {
            // Update successful, you may want to handle this accordingly (e.g., show a success message)
            console.log('Update successful!');
          } else {
            // Update failed, handle the error (e.g., show an error message)
            console.error('Update failed:', response.statusText);
          }
        } catch (error) {
          console.error('Fetch error:', error);
        }
      };
    
    
  
    return (
      <div className="col-lg-6 col-md-6">
        <b>Update Product</b>
        <form autoComplete="off" noValidate onSubmit={UpdateForm}>
          <div className="card">
            <div className="card-body">
            <div style={{ height: "200px", width: "100%", border: "3px solid grey" }}>
                {file && (
                    <img
                    src={URL.createObjectURL(file)}
                    className="card-img-top"
                    style={{ height: "100%", width: "100%" }}
                    alt="Product Preview"
                    />
                )}
                </div>
              <div className="form-group" style={{ padding: "10px", margin: "15px" }}>
                <input
                  type="file"
                  accept="image/*"
                  className={"form-control-file"}
                  onChange={(e) => setFile(e.target.files[0])}
                  id="image-uploader"
                />
              </div>
              <div className="form-group" style={{ margin: "15px" }}>
                <h6 className="text-start">Title</h6>
                <input
                  className={"form-control"}
                  placeholder="product Name"
                  name="productName"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group" style={{ margin: "15px" }}>
                <h6 className="text-start">Description</h6>
                <input
                  className="form-control"
                  placeholder="description"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="form-group" style={{ margin: "15px" }}>
                <h6 className="text-start">Price</h6>
                <input
                  className="form-control"
                  placeholder="price"
                  name="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="form-group" style={{ margin: "15px" }}>
                <h6 className="text-start">Type</h6>
                <select
                  className="form-control"
                  name="type"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="ganesh">Ganesh</option>
                  <option value="balaji">Balaji</option>
                  <option value="saraswathi">Saraswathi</option>
                  <option value="lakshmi">Lakshmi</option>
                  <option value="murugan">Murugan</option>
                  <option value="krishnan">Krishnan</option>
                  <option value="others">Others</option>
                  <option value="emboss">Emboss</option>
                </select>
              </div>
              <div className="form-group text-center">
                <button type="submit" className="btn btn-warning">
                  Update
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };
  
  export default Update;
  