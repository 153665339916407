import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const Slider1 = () => {
 const pictures = [
    {image: 'assets/images/prev_bg_1.jpg'},
    {image: 'assets/images/prev_bg_2.jpg'},
    {image: 'assets/images/prev_bg_3.jpg'}
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (

    <Slider {...settings}>
 
      {pictures.map((items)=>{
        return(
          <div style={{width:"100vw",maxHeight:"50vh"}} >
        <img src={items.image} alt="Tanjore Arts" style={{width:"100%",maxHeight:"50vh"}} />
        </div>
        )
      })}
      

    
    {/* Add more slides as needed */}
  </Slider>

  )
}

export default Slider1