import { useState } from 'react';
import './box.css';
import Slider1 from './Slider';
import ImageButton from './ImageButton';

const Home = ({ paintings }) => {
  const [selectedTypes, setSelectedTypes] = useState(["ganesh"]);

  const handleCheckboxChange = (event) => {
    const type = event.target.value;

    if (selectedTypes.includes(type)) {
      setSelectedTypes(selectedTypes.filter((selectedType) => selectedType !== type));
    } else {
      setSelectedTypes([...selectedTypes, type]);
    }
  };

  const handleAddToCart = (paint) => {
    // Placeholder logic to add item to cart
    console.log(`Adding ${paint.productName} to cart`);
    // Implement your logic to handle adding items to the cart (e.g., update cart state, API call, etc.)
  };

  const filtered = paintings.filter((painting) => selectedTypes.includes(painting.type));
  const reversed = filtered.reverse();

  return (
    <div>
      <Slider1 />
      <div className="main">
        <div className="wrap container" style={{ display: "flex", justifyContent: "center" }}>
          <div className="section group">
            <div className="cont span_3_of_3">
              <h2 className="head">Featured Products</h2>
              {reversed.map((paint) => {
                return (
                  <div
                    className="col_1_of_3 span_1_of_3"
                    style={{
                      maxWidth: "230px",
                      minWidth: "200px",
                      maxHeight: "380px",
                      minHeight: "380px",
                    }}
                    key={paint.id} // Add a unique key
                  >
                    <div className="inner_content clearfix">
                      <div className="product_image">
                        <img
                          src={paint.imageName}
                          style={{ height: "250px", width: "auto" }}
                          alt="Paintings"
                        />
                      </div>
                      <div className="sale-box">
                        <span className="on_sale title_shop">Sale</span>
                      </div>
                      <div>
                        <ImageButton handleAddToCart={handleAddToCart} paint={paint} />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="clear" />
      </div>
    </div>
  );
};

export default Home;
