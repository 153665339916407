import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Product from './Admin';
import './custom.css'


const Login = () => {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [registeredData, setRegisteredData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch registered data from the server when the component mounts
    fetchRegisteredData();
  }, []);

  const handleLogin = () => {
    registeredData.map((user) => {
      if (user.name === name && user.password === password && user.isActive === 0) {
        setError('Not Verified'); // Set error message
      }
      if (user.name === name && user.password === password && user.isActive === 1) {
        setIsLoggedIn(true);
        console.log('Valid');
      } else if (user.name === name && user.password === password && user.isActive === 0) {
        setError('Not Verified'); // Set error message
      } else {
        setError('Incorrect username or password. Please try again.'); // Set error message
      }
    });
  };

  const fetchRegisteredData = async () => {
    try {
      // Send a GET request to fetch registered data from the server
      const response = await fetch('http://re.srinivasalandpromoters.com/api/registers');

      if (response.ok) {
        // If the response status is OK (200), parse the response body
        const data = await response.json();
        setRegisteredData(data); // Update the state with the fetched data
      } else {
        // Log the error status text if the response status is not OK
        console.error('Error fetching registered data:', response.statusText);
      }
    } catch (error) {
      // Log any other errors that occur during the fetch operation
      console.error('Fetch error:', error);
    }
  };

  return (
    <div>
      {isLoggedIn ? (
        <Product/>
      ) : (
        <div style={{display:"flex",justifyContent:"center"}}>
        <div className="wrapper" >
          <div className="heading">
            <h2>Welcome!</h2>
            <p>Sign In to your account</p>
          </div>

          <div className="input-group">
            <input
              type="text"
              id="username"
              onChange={(e) => setName(e.target.value)}
              className="input-field"
              placeholder="Username"
            />
          </div>

          <div className="input-group">
            <input
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              className="input-field"
              placeholder="Password"
            />
          </div>

          {error && <div className="error-message">{error}</div>} {/* Display error message if exists */}

          <div className="input-group row">
            <div className="row">
              <a className="register" onClick={() => navigate('/register')}>
                Register Now
              </a>
            </div>
          </div>

          <div className="input-group">
            <button onClick={() => handleLogin()}>
              Login <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
        </div>
      )}
    </div>
  );
};

export default Login;
