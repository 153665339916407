import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './custom2.css'


const Registration = () => {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [email, setEmail] = useState('');
  const [registeredData, setRegisteredData] = useState([]);
  const navigate = useNavigate();

  const data = {
    name: name,
    phoneNo: phonenumber,
    password: password,
    email: email,
    isActive: 0,
  };

  useEffect(() => {
    // Fetch registered data from the server when the component mounts
    fetchRegisteredData();
  }, []);

  const fetchRegisteredData = async () => {
    // Send a GET request to fetch registered data from the server
    try {
      const response = await fetch('http://re.srinivasalandpromoters.com/api/registers');

      if (response.ok) {
        // If the response status is OK (200), parse the response body
        const data = await response.json();
        setRegisteredData(data); // Update the state with the fetched data
      } else {
        // Log the error status text if the response status is not OK
        console.error('Error fetching registered data:', response.statusText);
      }
    } catch (error) {
      // Log any other errors that occur during the fetch operation
      console.error('Fetch error:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://re.srinivasalandpromoters.com/api/registers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        // The request was successful, and you can handle the response here
        const responseData = await response.json();
        alert('Registered Successfully');
        navigate('/admin');
      } else {
        // Handle errors if the request was not successful
        console.error('Form submission failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <div>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="container">
            <div className="container1">
              <h1>Register</h1>
              <p>Please fill in this form to create an account.</p>
              <label htmlFor="email">
                <b>Username</b>
              </label>
              <input
                type="text"
                name="username"
                placeholder="Enter Username"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
              />
              <label htmlFor="psw">
                <b>Password</b>
              </label>
              <input
                type="password"
                placeholder="Enter Password"
                name="psw"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                required
              />
              <label htmlFor="email">
                <b>Email</b>
              </label>
              <input
                type="text"
                placeholder="Enter Email"
                name="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
              />
              <label htmlFor="email">
                <b>Phone Number</b>
              </label>
              <input
                type="phone"
                name="phone"
                placeholder={812345678}
                onChange={(e) => {
                  setPhonenumber(e.target.value);
                }}
                required
              />
              <p>
                By creating an account you agree to our{' '}
                <a style={{ color: 'dodgerblue' }}>Terms &amp; Privacy</a>.
              </p>
              <div className="clearfix">
                <button type="submit" className="btn btn-info">
                  Register
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Registration;
