import React from 'react'

const Footer = () => {
  return (
    <div>
     <div className="footer">
  <div className="footer-top">
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-lg-6">
          <ul className="f-list">
            <li><img src="assets/images/2.png" /><span className="f-text">Door Delivery Available </span><div className="clear" /></li>
          </ul>
        </div>
        <div className="col-md-6 col-lg-6">
          <ul className="f-list">
            <li><img src="assets/images/3.png" /><span className="f-text">Call us!  +91 99522 14909</span><div className="clear" /></li>
          </ul>
        </div>
        <div className="clear" />
  </div>
  </div>
  </div>
  <div className="footer-middle">
    <div className="container">
  <div className="row">
    <div className="col-md-4 col-lg-4">
      <h3 style={{color:"white",fontSize:"1.4em"}}>Address</h3>
      <div>
        <p style={{color:"white",fontSize:"0.9em"}}>
        55/7 Muthumari Ammankoil Sannathy,<br/>

        Meenakshipuram,<br/>

        Karaikudi – 630 001<br/>

        Cell +91 99522 14909<br/>

        Email : <a href="mailto:tanjorearts2015@gmail.com" style={{color:"white",textDecoration:"underline"}}>tanjorearts2015@gmail.com</a>  
        </p>
      </div>
    </div>
    <div className="col-md-4 col-lg-4">
    <h3 style={{color:"white",fontSize:"1.4em"}}>Proprietrix</h3>
      <div>
        <p style={{color:"white",fontSize:"0.9em"}}>
        Mrs.V.Solai valliapan,<br/>

        Proprietrix,<br/>

        Valli 2 Art,<br/>

        Karaikudi,<br/>

        Tamilnadu.
        </p>
      </div>
    </div>
    <div className="col-md-4 col-lg-4">
    <h3 style={{color:"white",fontSize:"1.4em"}}>Contact us</h3>
    <div>
      <b style={{color:"white",padding:"7px"}}>Mail</b>
    <input className='form-control' style={{width:"70%",margin:"15px"}}/>
    </div>
    <button className="btn btn-warning form-control" style={{width:"70%",margin:"15px"}}><b>Send</b></button>
    <div className="social-media" style={{width:"100%",margin:"15px"}}>
                <ul>
                  <li> <span className="simptip-position-bottom simptip-movable" data-tooltip="Google"><a href="#" target="_blank"> </a></span></li>
                  <li><span className="simptip-position-bottom simptip-movable" data-tooltip="Linked in"><a href="#" target="_blank"> </a> </span></li>
                  <li><span className="simptip-position-bottom simptip-movable" data-tooltip="Rss"><a href="#" target="_blank"> </a></span></li>
                  <li><span className="simptip-position-bottom simptip-movable" data-tooltip="Facebook"><a href="#" target="_blank"> </a></span></li>
                </ul>
              </div>
    </div>
  </div>
  </div>
  </div>
  <div className="footer-bottom" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>

      <div className="copy">
        <p style={{textAlign:"center"}}>© All Rights Reserved by Valli2Arts & Designed by<a href='http://www.kims.org.in' target="_blank"> Kims Softwares</a></p>
      </div>
      <div className="clear" />

  </div>
</div>

    </div>
  )
}

export default Footer